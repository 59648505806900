<template>
  <v-sheet min-height="100vh" class="py-2" color="grey">
    <v-card
      ref="foo"
      flat
      v-if="invoice"
      outlined
      min-height="100vh"
      width="842"
      class="mx-auto"
    >
      <component
        v-if="invoice.template.component.includes('InvoiceDefault')"
        :is="invoice.template.component"
        :create-mode="false"
        :value="invoice.colors"
      ></component>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import InvoiceDefault1 from "../../InvoiceTemplates/templates/InvoiceDefault1.vue";
import InvoiceDefault2 from "../../InvoiceTemplates/templates/InvoiceDefault2.vue";
import InvoiceDefault3 from "../../InvoiceTemplates/templates/InvoiceDefault3.vue";
import InvoiceDefault4 from "../../InvoiceTemplates/templates/InvoiceDefault4.vue";
import InvoiceDefault5 from "../../InvoiceTemplates/templates/InvoiceDefault5.vue";

export default {
  components: {
    InvoiceDefault1,
    InvoiceDefault2,
    InvoiceDefault3,
    InvoiceDefault4,
    InvoiceDefault5,
  },
  data: () => ({
    loading: false,
  }),
  watch: {
    // "$route.params.id": "fetchInvoice",
  },
  computed: {
    ...mapGetters("invoice", ["invoice"]),
  },
  created() {
    this.fetchInvoice({ id: this.$route.params.id });
    this.set_create_mode(false);
  },
  methods: {
    ...mapMutations("invoice", ["set_create_mode"]),
    ...mapActions("invoice", ["fetchInvoice"]),
    tests() {
      this.$refs.foo.$el.innerHTML;
    },
    print() {
      window.print();
    },
  },
};
</script>

<style>
</style>